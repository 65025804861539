const { CAT } = require('../constants/routes');

module.exports = [
	{
		label: 'Home Decor',
		slug: 'home-decor',
		desc: `Looking to spruce up your home? Well, you’ve come to the right place. We’ve got the hottest deals on everything from decorative pillows to bathroom accessories. On a budget? Then be sure to check out our home decor clearance range, you’re guaranteed to pick up a bargain!`,
		deals: '132K',
		image: '/shared/images/homepage-categories/home-decor.jpg',
		link: `${CAT}/home-decor`,
		navigator: {
			image: '/category/home-decor.png',
			styles: {
				item: {
					background: `linear-gradient(90deg, rgba(60,191,139,1) 0%, rgba(40,208,144,1) 100%)`,
					image1: '/category/home-decor/clock.png',
					image2: '/category/home-decor/sopa.png',
					image3: '/category/home-decor/plant.png',
					image4: '/category/home-decor/table.png',
				},
				label: {},
				img: {
					bottom: '0px',
					right: '2px',
					height: '95px',
				},
			},
		},
		icon: '/category/dropdown/home-decor.png'
	},
	{
		label: 'Electronics',
		slug: 'electronics',
		desc: `Whether you’re a fan of fun gadgets, or you’re in the market for something more practical like a new laptop for work – our carefully curated list of deals on electronics is sure to help you find a new toy. You can rely on us to find you quality electronics at the best prices.`,
		deals: '374K',
		image: '/shared/images/homepage-categories/electronics.jpg',
		link: `${CAT}/electronics`,
		navigator: {
			image: '/category/electronics.png',
			styles: {
				item: {
					background: `linear-gradient(270deg, #3CBFA7 0%, #29ADBF 100%)`,
					image1: '/category/electronics/earphones.png',
					image2: '/category/electronics/usb.png',
					image3: '/category/electronics/watch.png',
					image4: '/category/electronics/ipad.png',
				},
				label: {},
				img: {
					height: '75px',
					right: '-11px',
					top: '-7px',
				},
			},
		},
		icon: '/category/dropdown/electronics.png'
	},
	{
		label: 'Books',
		slug: 'books',
		deals: '547K',
		desc: `In this modern world, people often swap books for screens. We don’t think that’s right. There’s nothing quite like the feeling, and even the smell of a good book. There is something very satisfying about turning pages and not having to worry about battery life or WiFi connections. Novel or biography, cookbook or textbook – grab yourself a deal today!`,
		image: '/shared/images/homepage-categories/books.jpg',
		link: `${CAT}/books`,
		navigator: {
			image: '/category/books.png',
			styles: {
				item: {
					background: `linear-gradient(270deg, #967DFC 0%, #6E7BFE 100%)`,
					image1: '/category/books/pen.png',
					image2: '/category/books/book.png',
					image3: '/category/books/news.png',
					image4: '/category/books/book2.png',
				},
				label: {},
				img: {
					height: '100px',
					right: '-5px',
					top: '-11px',
				},
			},
		},
		icon: '/category/dropdown/books.png'
	},
	{
		label: 'Computers',
		slug: 'computers',
		deals: '351K',
		desc: `Purchasing a new computer requires some forethought and research. After all, it’s not a purchase you regularly make so you want to be sure you’re buying something you will like. We’ve made the process a little easier for you by sourcing the best deals on the best computers.`,
		image: '/shared/images/homepage-categories/computers.jpg',
		link: `${CAT}/computers`,
		navigator: {
			image: '/category/computers.png',
			styles: {
				item: {
					background: `linear-gradient(270deg, #FB9394 0%, #FC6C6D 100%), #E8E8E8`,
					image1: '/category/computers/cursor.png',
					image2: '/category/computers/mouse.png',
					image3: '/category/computers/laptop.png',
					image4: '/category/computers/mac.png',
				},
				label: {},
				img: {
					height: '115px',
					right: '-5px',
					bottom: '-19px',
				},
			},
		},
		icon: '/category/dropdown/computers.png'
	},
	{
		label: 'Men Fashion',
		slug: 'men-fashion',
		deals: '205K',
		desc: `Being fashionable doesn’t have to be expensive! There are so many great clothing brands out there at reasonable prices, you just have to know where to look. And where’s that? Right here! We’ve found thousands of deals on everything from sneakers to shirts, and sunglasses to watches.`,
		image: '/shared/images/homepage-categories/men-fashion.jpg',
		link: `${CAT}/men-fashion`,
		navigator: {
			image: '/category/men-fashion.png',
			styles: {
				item: {
					background: ` linear-gradient(270deg, #78AEFF 0%, #316BFF 100%), linear-gradient(270deg, #F97DFC 0%, #D940FF 100%), linear-gradient(270deg, #967DFC 0%, #6E7BFE 100%), #E8E8E8`,
					image1: '/category/men-fashion/shoes.png',
					image2: '/category/men-fashion/watch.png',
					image3: '/category/men-fashion/shirt.png',
					image4: '/category/men-fashion/shoes2.png',
				},
				label: {
					maxWidth: '75px',
				},
				img: {
					height: '79px',
					right: '4px',
					bottom: '0px',
				},
			},
		},
		icon: '/category/dropdown/men-fashion.png'
	},
	{
		label: 'Women Fashion',
		slug: 'women-fashion',
		deals: '248K',
		desc: `The world of women’s fashion can be overwhelming, there’s so much to choose from. Maxi skirts, mini skirts, pleated skirts – high heels, pumps, flats. There is an almost endless array of clothing items for women to choose from. We’ve curated a list of deals to satisfy the needs of any fashion-conscious women, no matter her style.`,
		image: '/shared/images/homepage-categories/women-fashion.jpg',
		link: `${CAT}/women-fashion`,
		navigator: {
			image: '/category/women-fashion.png',
			styles: {
				item: {
					background: `linear-gradient(270deg, #F97DFC 0%, #DE59FF 100%) `,
					image1: '/category/women-fashion/hat.png',
					image2: '/category/women-fashion/bag.png',
					image3: '/category/women-fashion/dress.png',
					image4: '/category/women-fashion/shoes.png',
				},
				label: {
					maxWidth: '75px',
				},
				img: {
					height: '110px',
					right: '2px',
					top: '-19px',
				},
			},
		},
		icon: '/category/dropdown/women-fashion.png'
	},
	{
		label: 'Baby Care',
		slug: 'baby-care',
		deals: '147K',
		desc: `Understandably, babies take up a lot of your time. There are so many things to think about that sometimes it might feel like there aren’t enough hours in the day. So, when it comes to baby care products, we want to help make your life just that little bit easier. Here you’ll find deals on all of the essential baby care equipment you could ever need. `,
		image: '/shared/images/homepage-categories/baby-care.jpg',
		link: `${CAT}/baby-care`,
		navigator: {
			image: '/category/baby-care.png',
			styles: {
				item: {
					background: `linear-gradient(270deg, #3ACAFA 0%, #3AB8FD 100%)`,
					image1: '/category/baby-care/bear.png',
					image2: '/category/baby-care/bird.png',
					image3: '/category/baby-care/bottle.png',
					image4: '/category/baby-care/shirt.png',
				},
				label: {},
				img: {
					height: '82px',
					right: '16px',
					top: '-10px',
				},
			},
		},
		icon: '/category/dropdown/baby-care.png'
	},
	{
		label: 'Sport and Outdoor',
		slug: 'sport-and-outdoor',
		deals: '236K',
		desc: `For all the adventure-loving thrillseekers out there, we have a list of products that you’ll love. Whether you’re into camping, hiking or any other outdoor activity or sport, you'll find a deal so irresistible that you won’t be able to stop yourself snapping it up.`,
		image: '/shared/images/homepage-categories/sport.jpg',
		link: `${CAT}/sport-and-outdoor`,
		navigator: {
			image: '/category/sport.png',
			styles: {
				item: {
					background: `linear-gradient(270deg, #FFBB69 0%, #F59391 100%)`,
					image1: '/category/sport/basket.png',
					image2: '/category/sport/ball.png',
					image3: '/category/sport/shoes.png',
					image4: '/category/sport/soccer.png',
				},
				label: {
					textAlign: 'center',
					maxWidth: '75px',
				},
				img: {
					height: '67px',
					right: '11px',
					top: '-7px',
				},
			},
		},
		icon: '/category/dropdown/sport-and-outdoor.png'
	},
];
