import { fetchProductData } from 'src/lib/api';
import TestsManager from 'src/lib/managers/TestsManager';

/**
 * DEPRECATED
 * TODO - fix category request data to feed and delete these functions
 */

const checkIsEnterToTest = props => {
	const { data = {}, isForcedTest, isInTest } = props;
	const { testData = {} } = data;
	const currentTest = TestsManager.getCurrentTest();
	if (
		(isInTest && testData.testId === currentTest.testId && testData.testGroup === currentTest.testGroup) || // just for update trackTest
		(!isInTest && testData.testId) ||
		(isForcedTest && testData.testId)
	) {
		TestsManager.setCurrentTest(testData);
	}
};

export const fetchProductDataAggregation = props => {
	let { queryParams, dispatch, vendorProps, deleteVendors = false, nextPage = 1 } = props;
	const currentTest = TestsManager.getCurrentTest();
	const forcedTest = TestsManager.getForcedTest();
	const isInTest = currentTest.testId;
	const isForcedTest = forcedTest && forcedTest.ftid;
	if (!isInTest) {
		queryParams = {
			...queryParams,
			...forcedTest,
		};
	}
	const test = isForcedTest ? forcedTest : currentTest;

	queryParams = {
		...queryParams,
		maxYResults: 0,
	};

	const resultPromise = fetchProductData({ ...queryParams, vendorProps: JSON.stringify(vendorProps), ...test });
	resultPromise
		.then(data => {
			if (deleteVendors) {
				delete queryParams['vendors'];
			}
			queryParams.page = queryParams.page || nextPage;
			checkIsEnterToTest({ dispatch, data, isForcedTest });
			delete queryParams['sessionStart'];
		})
		.catch(err => console.error('Error fetch data from api', err));

	return resultPromise;
};
