import { fetchTrendingSearchesAPI } from 'src/lib/api';
import categoriesData from 'src/lib/constants/categoriesData';

export const FETCH_TRENDING_SEARCHES = 'FETCH_TRENDING_SEARCHES';
export const FETCH_TRENDING_SEARCHES_SUCCESS = 'FETCH_TRENDING_SEARCHES_SUCCESS';
export const FETCH_TRENDING_SEARCHES_FAIL = 'FETCH_TRENDING_SEARCHES_FAIL';

export const FETCH_BEST_BARGAINS = 'FETCH_BEST_BARGAINS';
export const FETCH_BEST_BARGAINS_SUCCESS = 'FETCH_BEST_BARGAINS_SUCCESS';
export const FETCH_BEST_BARGAINS_FAIL = 'FETCH_BEST_BARGAINS_FAIL';

// REDUCERS
const initialState = {};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_TRENDING_SEARCHES:
			return {
				...state,
				loadingTrendingSearches: true,
			};
		case FETCH_TRENDING_SEARCHES_SUCCESS:
			return {
				...state,
				loadingTrendingSearches: false,
				isTrendingSearchesSuccess: true,
				trendingSearches: action.payload.termsList,
			};
		case FETCH_TRENDING_SEARCHES_FAIL:
			return {
				...state,
				loadingTrendingSearches: false,
				isTrendingSearchesSuccess: false,
				trendingSearchesError: action.error,
			};
		default:
			return state;
	}
}

// GETTERS
export const getCategories = () => categoriesData;
export const getTrendingSearches = state => state.trendingSearches;
export const getIsLoadingTrendingSearches = state => state.loadingTrendingSearches;

//ACTIONS

// ACTIONS WITH PROMISES
export const fetchTrendingSearches = limit => {
	return {
		types: [FETCH_TRENDING_SEARCHES, FETCH_TRENDING_SEARCHES_SUCCESS, FETCH_TRENDING_SEARCHES_FAIL],
		promise: () => fetchTrendingSearchesAPI({}, limit),
	};
};
