import { setAction } from 'src/lib/redux/helpers';

export const ADD_USER_CLICKS = 'ADD_USER_CLICKS';

const initialState = {
    userClicks: 0
};

// REDUCER
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD_USER_CLICKS:
            return {
                ...state,
                userClicks: state.userClicks + 1,
            };
        default:
            return state;
    }
}

// GETTERS
export const getUserClicksAmount = state => state.userClicks;

//ACTIONS
export const addToUserClicks = () => setAction(ADD_USER_CLICKS);

// ACTIONS WITH PROMISES
