export const SET_SIDEBAR = 'SET_SIDEBAR';

// REDUCERS
const initialState = {
	isSidebarOpen: false,
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_SIDEBAR:
			return {
				...state,
				isSidebarOpen: action.payload,
			};
		default:
			return state;
	}
}

// GETTERS
export const getIsSidebarOpen = state => state.isSidebarOpen;

//ACTIONS
export const setSidebar = isOpen => ({ type: SET_SIDEBAR, payload: isOpen });
