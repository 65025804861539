import categoriesData from 'src/lib/constants/categoriesData';
import findIndex from 'lodash/findIndex';
import { fetchProductDataAggregation } from 'src/lib/managers/FeedManager';

export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const SET_CURRENT_CATEGORY_INDEX = 'SET_CURRENT_CATEGORY_INDEX';
export const FETCH_CATEGORY_DATA = 'FETCH_CATEGORY_DATA';
export const FETCH_CATEGORY_DATA_SUCCESS = 'FETCH_CATEGORY_DATA_SUCCESS';
export const FETCH_CATEGORY_DATA_FAIL = 'FETCH_CATEGORY_DATA_FAIL';

// REDUCERS
const initialState = {
	currentCategory: null,
	categories: [],
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_CATEGORY: {
			const nextCategory = action.payload.toLowerCase();
			const currentCategory = findIndex(categoriesData, cat => cat.label.toLowerCase() === nextCategory);
			return {
				...state,
				currentCategory: categoriesData[currentCategory],
				currentCategoryIndex: currentCategory,
			};
		}

		case SET_CURRENT_CATEGORY_INDEX: {
			return {
				...state,
				currentCategory: categoriesData[action.payload],
				currentCategoryIndex: action.payload,
			};
		}

		case FETCH_CATEGORY_DATA:
			return {
				...state,
				isLoadingCategory: true,
				isLoadedCategory: false,
			};

		case FETCH_CATEGORY_DATA_SUCCESS: {
			if (action.payload.isCache) {
				return {
					...state,
					isLoadingCategory: false,
					isLoadedCategory: true,
				};
			}
			const _categories = state.categories;
			_categories[state.currentCategoryIndex] = action.payload;
			return {
				...state,
				categories: _categories,
				isLoadingCategory: false,
				isLoadedCategory: true,
			};
		}
		case FETCH_CATEGORY_DATA_FAIL:
			return {
				...state,
				isLoadedCategory: false,
				isLoadingCategory: false,
			};

		default:
			return state;
	}
}

// GETTERS
export const getCurrentCategory = state => state.currentCategory;
export const getCurrentCategoryIndex = state => state.currentCategoryIndex;
export const getCurrentCategoryPayload = state => state.categories[state.currentCategoryIndex] || { productAds: [] };
export const getIsLoadingCategory = state => state.isLoadingCategory;

//ACTIONS
export const setCurrentCategory = cat => ({ type: SET_CURRENT_CATEGORY, payload: cat || '' });
export const setCurrentCategoryIndex = index => ({ type: SET_CURRENT_CATEGORY_INDEX, payload: index });

// TODO
export const searchCategoryProducts = queryParams => ({
	types: [FETCH_CATEGORY_DATA, FETCH_CATEGORY_DATA_SUCCESS, FETCH_CATEGORY_DATA_FAIL],
	promise: props => {
		const { state, dispatch } = props;
		if (state.category.categories[state.category.currentCategoryIndex]) {
			return Promise.resolve({ isCache: true });
		}

		return fetchProductDataAggregation({ queryParams, dispatch });
	},
});